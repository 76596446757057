import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useSetClientArchivedMutation} from '@/mutations/client';
import type {Client} from '@/types/client';

type Props = {
    client : Client;
};

const ToggleArchivedButton = ({client} : Props) : JSX.Element => {
    const setClientArchivedMutation = useSetClientArchivedMutation(client.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleClick = async () => {
        if ((await handleMutation(setClientArchivedMutation, {archived: !client.archived})).success) {
            enqueueSnackbar(`Client has been ${client.archived ? 'restored' : 'archived'}`, {variant: 'success'});
        }
    };

    return (
        <LoadingButton
            size="small"
            loading={setClientArchivedMutation.isLoading}
            onClick={handleClick}
        >
            {client.archived ? 'Restore' : 'Archive'}
        </LoadingButton>
    );
};

export default ToggleArchivedButton;
