import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useConfirm} from 'react-confirm-hook';
import {EditPaymentDialog} from '../PaymentDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useDeletePaymentMutation} from '@/mutations/payment';
import type {Invoice} from '@/types/invoice';
import type {Payment} from '@/types/payment';

type Props = {
    invoice : Invoice;
    payment : Payment;
};

const receiptFormatter = DateTimeFormatter.ofPattern('MMM dd, yyyy').withLocale(Locale.US);

const PaymentListItem = ({invoice, payment} : Props) : JSX.Element => {
    const deletePaymentMutation = useDeletePaymentMutation(invoice.id, payment.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const confirmDelete = useConfirm(ConfirmDialog);

    const handleDelete = async () => {
        if ((await handleMutation(deletePaymentMutation, undefined)).success) {
            enqueueSnackbar('Payment has been deleted', {variant: 'success'});
        }
    };

    const amountFormatter = new Intl.NumberFormat(
        'en-US',
        {style: 'currency', currency: invoice.currencyCode}
    );

    return (
        <ListItem
            disablePadding
            secondaryAction={(
                <IconButton edge="end" onClick={() => {
                    confirmDelete({
                        title: 'Confirm Delete',
                        message: 'Do you really want to delete this payment?',
                        onConfirm: handleDelete,
                    });
                }}>
                    <DeleteIcon/>
                </IconButton>
            )}
        >
            <ListItemButton onClick={() => {
                setEditDialogOpen(true);
            }}>
                <ListItemText
                    primary={`${payment.receiptDate.format(receiptFormatter)}`}
                    secondary={amountFormatter.format(payment.amount / 100)}
                />
            </ListItemButton>

            <EditPaymentDialog
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                invoice={invoice}
                payment={payment}
            />
        </ListItem>
    );
};

export default PaymentListItem;
