import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {RawRecurringInvoice} from '@/types/recurring-invoice';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type RecurringInvoiceValues = Omit<RawRecurringInvoice, 'id' | 'client' | 'lastInvoiceDate'> & {
    clientId : string;
};

export const useCreateRecurringInvoiceMutation = () : UseMutationResult<
    void,
    unknown,
    RecurringInvoiceValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : RecurringInvoiceValues) => {
        const response = await fetch(apiUrl('/recurring-invoices').toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create recurring invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['recurring-invoices']);
        },
    });
};

export const useUpdateRecurringInvoiceMutation = (recurringInvoiceId : string) : UseMutationResult<
    void,
    unknown,
    RecurringInvoiceValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : RecurringInvoiceValues) => {
        const response = await fetch(apiUrl(`/recurring-invoices/${recurringInvoiceId}`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update recurring invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['recurring-invoices']);
        },
    });
};

export const useDeleteRecurringInvoiceMutation = (recurringInvoiceId : string) : UseMutationResult<
    void,
    unknown,
    void
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async () => {
        const response = await fetch(apiUrl(`/recurring-invoices/${recurringInvoiceId}`).toString(), {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to delete recurring invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['recurring-invoices']);
        },
    });
};
