export const apiUrl = (path : string) : URL => new URL(path, import.meta.env.VITE_APP_API_ENDPOINT);

export type CollectionResponse<T> = {
    items : T[];
};

export type InfiniteCollectionResponse<T> = {
    items : T[];
    nextCursor ?: string;
};
