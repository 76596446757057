import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {RawClient} from '@/types/client';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type ClientValues = Omit<RawClient, 'id' | 'archived'>;

export const useCreateClientMutation = () : UseMutationResult<
    void,
    unknown,
    ClientValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : ClientValues) => {
        const response = await fetch(apiUrl('/clients').toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create client');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['clients']);
        },
    });
};

export const useUpdateClientMutation = (clientId : string) : UseMutationResult<
    void,
    unknown,
    ClientValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : ClientValues) => {
        const response = await fetch(apiUrl(`/clients/${clientId}`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update client');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['clients']);
        },
    });
};

type ToggleArchivedValues = {
    archived : boolean;
};

export const useSetClientArchivedMutation = (clientId : string) : UseMutationResult<
    void,
    unknown,
    ToggleArchivedValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : ToggleArchivedValues) => {
        const response = await fetch(apiUrl(`/clients/${clientId}/archived`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to toggle archived status');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['clients']);
        },
    });
};
