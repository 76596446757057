import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import {Alert, Box, Button, IconButton, LinearProgress, Stack, Typography} from '@mui/material';
import {countries} from 'countries-list';
import {formatAddress} from 'localized-address-format';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DownloadInvoiceButton from './DownloadInvoiceButton';
import {EditInvoiceDialog} from './InvoiceDialog';
import LineItemsTable from './LineItemsTable';
import {CreatePaymentDialog} from './PaymentDialog';
import PaymentsList from './PaymentsList';
import SendInvoiceDialog from './SendInvoiceDialog';
import ToggleInvoiceStatusButton from './ToggleInvoiceStatusButton';
import RetryableError from '@/components/RetryableError';
import {useInvoiceQuery, useLineItemsQuery} from '@/queries/invoice';

type UrlParams = {
    invoiceId : string;
};

const Invoice = () : JSX.Element => {
    const {invoiceId} = useParams<UrlParams>();

    if (!invoiceId) {
        throw new Error('URL parameter "invoiceId" missing');
    }

    const navigate = useNavigate();
    const [createPaymentDialogOpen, setCreatePaymentDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [sendDialogOpen, setSendDialogOpen] = useState(false);
    const invoiceQuery = useInvoiceQuery(invoiceId);
    const lineItemsQuery = useLineItemsQuery(invoiceId);

    if (invoiceQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (invoiceQuery.isError) {
        return (
            <RetryableError
                message="Failed to load invoice"
                onRetry={() => void invoiceQuery.refetch()}
            />
        );
    }

    const invoice = invoiceQuery.data;
    const {client} = invoice;

    const address = formatAddress({
        name: client.name,
        postalCode: client.zipCode,
        locality: client.city,
        postalCountry: client.countryCode,
        administrativeArea: client.state,
        addressLines: [client.addressLineOne, client.addressLineTwo].filter(Boolean),
    });
    address.push(countries[client.countryCode as keyof typeof countries].name);

    const amountFormatter = new Intl.NumberFormat(
        'en-US',
        {style: 'currency', currency: invoice.currencyCode}
    );

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{display: 'flex', pl: 2, pr: 2, pb: 1, mb: 2, borderBottom: 1, borderColor: 'divider'}}
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton edge="start" sx={{mr: 1}} onClick={() => {
                        navigate('/');
                    }}>
                        <ArrowBackIcon/>
                    </IconButton>

                    <Typography variant="h6">
                        Invoice {invoice.invoiceNumber}
                    </Typography>
                </Box>
            </Stack>

            <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DownloadInvoiceButton invoice={invoice}/>
                <ToggleInvoiceStatusButton invoice={invoice}/>

                {invoice.status === 'draft' && (
                    <>
                        <Button startIcon={<SendIcon/>} variant="contained" onClick={() => {
                            setSendDialogOpen(true);
                        }}>
                            Send
                        </Button>

                        <Button
                            startIcon={<EditIcon/>}
                            variant="contained"
                            disabled={!lineItemsQuery.data}
                            onClick={() => {
                                setEditDialogOpen(true);
                            }}
                        >
                            Edit
                        </Button>
                    </>
                )}
            </Stack>

            <Box sx={{mt: 2}}>
                {invoice.amounts.open > 0
                    ? (
                        <Alert severity="warning">
                            There is an open amount of {amountFormatter.format(invoice.amounts.open / 100)}.
                        </Alert>
                    )
                    : (
                        <Alert severity="success">
                            The invoice has been paid.
                        </Alert>
                    )
                }
            </Box>

            {invoice.status === 'draft' && lineItemsQuery.data && (
                <EditInvoiceDialog
                    open={editDialogOpen}
                    onClose={() => {
                        setEditDialogOpen(false);
                    }}
                    invoice={invoice}
                    lineItems={lineItemsQuery.data}
                />
            )}

            {invoice.status === 'draft' && (
                <SendInvoiceDialog
                    open={sendDialogOpen}
                    onClose={() => {
                        setSendDialogOpen(false);
                    }}
                    invoice={invoice}
                />
            )}

            <Typography sx={{mt: 2, mb: 2, whiteSpace: 'pre-line'}}>{address.join('\n')}</Typography>

            <LineItemsTable invoice={invoice}/>

            <Box sx={{mt: 2}}><PaymentsList invoice={invoice}/></Box>

            <Button variant="contained" sx={{mt: 2}} onClick={() => {
                setCreatePaymentDialogOpen(true);
            }}>
                Add payment
            </Button>

            <CreatePaymentDialog
                open={createPaymentDialogOpen}
                onClose={() => {
                    setCreatePaymentDialogOpen(false);
                }}
                invoice={invoice}
            />
        </>
    );
};

export default Invoice;
