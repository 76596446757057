import {zodResolver} from '@hookform/resolvers/zod';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, MenuItem,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {RhfSwitch, RhfTextField} from 'mui-rhf-integration';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import FormDialog from '@/components/FormDialog';
import type {FormDialogFormProps} from '@/components/FormDialog';
import RhfCountrySelect from '@/components/RhfCountrySelect';
import type {Client} from '@/types/client';
import {errorMap} from '@/utils/zod';

const schema = z.object({
    name: z.string().trim().min(1),
    displayName: z.string().trim().default(''),
    currencyCode: z.string().length(3).transform(value => value.toUpperCase()),
    addressLineOne: z.string().trim().min(1),
    addressLineTwo: z.string().trim().default(''),
    zipCode: z.string().trim().min(1),
    state: z.string().trim().default(''),
    city: z.string().trim().min(1),
    countryCode: z.string().length(2).transform(value => value.toUpperCase()),
    taxable: z.boolean().default(false),
});

export type FormValues = z.infer<typeof schema>;

type Props = {
    client ?: Client;
    onSubmit : (values : FormValues) => Promise<boolean>;
    open : boolean;
    onClose : () => void;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const ClientForm = ({wrapSubmit, onClose, client, onSubmit} : FormProps) : JSX.Element => {
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: client,
    });

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        await onSubmit(values);
    }), [wrapSubmit, onSubmit]);

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                {client ? 'Edit' : 'Create'} Client
            </DialogTitle>

            <DialogContent dividers>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="name"
                            label="Name"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="displayName"
                            label="Display Name"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <RhfTextField
                            control={form.control}
                            name="addressLineOne"
                            label="Address Line 1"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <RhfTextField
                            control={form.control}
                            name="addressLineTwo"
                            label="Address Line 2"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={3}>
                        <RhfTextField
                            control={form.control}
                            name="zipCode"
                            label="ZIP Code"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={4}>
                        <RhfTextField
                            control={form.control}
                            name="state"
                            label="State"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={5}>
                        <RhfTextField
                            control={form.control}
                            name="city"
                            label="City"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <RhfCountrySelect
                            control={form.control}
                            name="countryCode"
                            textFieldProps={{
                                label: 'Country',
                                required: true,
                                fullWidth: true,
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="currencyCode"
                            label="Currency"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="EUR">Euro</MenuItem>
                            <MenuItem value="USD">US-Dollar</MenuItem>
                        </RhfTextField>
                    </Grid2>
                    <Grid2 xs={12} sm={6} alignSelf="center">
                        <FormControlLabel
                            control={<RhfSwitch control={form.control} name="taxable"/>}
                            label="Taxable"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </DialogForm>
    );
};

const ClientDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={ClientForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default ClientDialog;
