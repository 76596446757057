import {LocalDate} from '@js-joda/core';
import DecimalJs from 'decimal.js';
import {mapRawToClient} from '@/mappers/client';
import type {Invoice, LineItem, RawInvoice, RawLineItem} from '@/types/invoice';

export const mapRawToInvoice = (raw : RawInvoice) : Invoice => ({
    ...raw,
    client: mapRawToClient(raw.client),
    issueDate: LocalDate.parse(raw.issueDate),
    discount: raw.discount ? new DecimalJs(raw.discount) : null,
    vat: raw.vat ? new DecimalJs(raw.vat) : null,
});

export const mapRawToLineItem = (raw : RawLineItem) : LineItem => raw;
