import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToClient} from '@/mappers/client';
import type {Client, RawClient} from '@/types/client';
import type {CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useClientsQuery = (archived : boolean) : UseQueryResult<Client[]> => {
    const fetch = useOidcFetch();

    return useQuery(['clients', archived ? 'archived' : 'unarchived'], async ({signal}) => {
        const url = apiUrl('/clients');
        url.searchParams.set('archived', archived ? 'true' : 'false');

        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch clients');
        }

        const rawClients = (await response.json() as CollectionResponse<RawClient>).items;
        return rawClients.map(mapRawToClient);
    });
};
