import {LocalDate} from '@js-joda/core';
import DecimalJs from 'decimal.js';
import {mapRawToClient} from '@/mappers/client';
import type {RawRecurringInvoice, RecurringInvoice} from '@/types/recurring-invoice';

export const mapRawToRecurringInvoice = (raw : RawRecurringInvoice) : RecurringInvoice => ({
    ...raw,
    client: mapRawToClient(raw.client),
    startDate: LocalDate.parse(raw.startDate),
    endDate: LocalDate.parse(raw.endDate),
    lastInvoiceDate: raw.lastInvoiceDate ? LocalDate.parse(raw.lastInvoiceDate) : null,
    discount: raw.discount ? new DecimalJs(raw.discount) : null,
    vat: raw.vat ? new DecimalJs(raw.vat) : null,
});
