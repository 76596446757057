import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import ClientDialog from './ClientDialog';
import type {FormValues} from './ClientDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateClientMutation} from '@/mutations/client';

type Props = {
    open : boolean;
    onClose : () => void;
};

const CreateClientDialog = ({open, onClose} : Props) : JSX.Element => {
    const createClientMutation = useCreateClientMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(createClientMutation, values)).success) {
            enqueueSnackbar('Client has been created', {variant: 'success'});
            onClose();
        }

        return false;
    }, [createClientMutation, handleMutation, enqueueSnackbar]);

    return (
        <ClientDialog onSubmit={handleSubmit} open={open} onClose={onClose}/>
    );
};

export default CreateClientDialog;
