import AddBoxIcon from '@mui/icons-material/AddBox';
import {Button, Paper, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import {CreateInvoiceDialog} from './InvoiceDialog';
import InvoiceList from './InvoiceList';

const Invoices = () : JSX.Element => {
    const [currentTab, setCurrentTab] = useState('open');
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{display: 'flex', pl: 2, pr: 2, pb: 1, mb: 2, borderBottom: 1, borderColor: 'divider'}}
            >
                <Typography variant="h6">
                    Invoices
                </Typography>

                <Button
                    size="small"
                    startIcon={<AddBoxIcon/>}
                    onClick={() => {
                        setCreateDialogOpen(true);
                    }}
                >
                    Create invoice
                </Button>
            </Stack>

            <CreateInvoiceDialog open={createDialogOpen} onClose={() => {
                setCreateDialogOpen(false);
            }}/>

            <Paper sx={{mb: 2}}>
                <Tabs value={currentTab} onChange={(event, value) => {
                    setCurrentTab(value as string);
                }}>
                    <Tab value="open" label="Open"/>
                    <Tab value="closed" label="Closed"/>
                </Tabs>
            </Paper>

            <InvoiceList open={currentTab === 'open'}/>
        </>
    );
};

export default Invoices;
