import {zodResolver} from '@hookform/resolvers/zod';
import {convert} from '@js-joda/core';
import {
    Box,
    Button,
    Card, CardActions, CardContent,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DecimalJs from 'decimal.js';
import {RhfDatePicker, RhfTextField} from 'mui-rhf-integration';
import {useCallback, useEffect} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import FormDialog from '@/components/FormDialog';
import type {FormDialogFormProps} from '@/components/FormDialog';
import RhfClientSelect from '@/components/RhfClientSelect/RhfClientSelect';
import type {Client} from '@/types/client';
import type {Invoice, LineItem} from '@/types/invoice';
import {errorMap, zDecimalJs} from '@/utils/zod';

const schema = z.object({
    client: z.object({
        id: z.string(),
        taxable: z.boolean(),
        currencyCode: z.string(),
    }),
    issueDate: z.date(),
    dueAfterDays: z.string(),
    discount: z.preprocess(
        value => (!value || value === '') ? '0' : value,
        zDecimalJs
            .refine(value => value.greaterThanOrEqualTo(0), 'Value too small')
            .refine(value => value.lessThanOrEqualTo(100), 'Value too large')
            .transform(value => value.eq(0) ? null : value.toString())
    ),
    vat: z.preprocess(
        value => (!value || value === '') ? '0' : value,
        zDecimalJs
            .refine(value => value.greaterThanOrEqualTo(0), 'Value too small')
            .refine(value => value.lessThanOrEqualTo(100), 'Value too large')
            .transform(value => value.eq(0) ? null : value.toString())
    ),
    currencyCode: z.string().length(3).transform(value => value.toUpperCase()),
    lineItems: z.array(z.object({
        description: z.string().trim().min(1),
        quantity: z.string().regex(/^\d+$/),
        unit: z.enum(['none', 'hours', 'days']),
        unitAmount: zDecimalJs
            .refine(value => value.greaterThan(0), 'Must be a positive value')
            .refine(value => value.decimalPlaces() <= 2, 'Must have two decimal places at most')
            .transform(value => value.mul(100).floor().toString()),
    })).min(1),
});

export type FormValues = z.infer<typeof schema>;

type Props = {
    invoice ?: Invoice;
    lineItems ?: LineItem[];
    onSubmit : (values : FormValues) => Promise<boolean>;
    open : boolean;
    onClose : () => void;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const InvoiceForm = ({wrapSubmit, onClose, invoice, lineItems, onSubmit} : FormProps) : JSX.Element => {
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: invoice
            ? {
                client: invoice.client,
                issueDate: convert(invoice.issueDate).toDate(),
                dueAfterDays: invoice.dueAfterDays.toString(),
                discount: invoice.discount?.toString(),
                vat: invoice.vat?.toString(),
                currencyCode: invoice.currencyCode,
                lineItems: lineItems
                    ? lineItems.map(lineItem => ({
                        description: lineItem.description,
                        quantity: lineItem.quantity.toString(),
                        unit: lineItem.unit,
                        unitAmount: new DecimalJs(lineItem.unitAmount).div(100).toFixed(2),
                    }))
                    : [],
            }
            : {
                issueDate: new Date(),
                dueAfterDays: '30',
                lineItems: [{quantity: '1', unit: 'none'}],
            },
    });

    useEffect(() => {
        const subscription = form.watch((value, {name, type}) => {
            if (name !== 'client' || type !== 'change') {
                return;
            }

            if (!value.client) {
                return;
            }

            form.setValue('vat', (value.client as Client).taxable ? '19' : '');
            form.setValue('currencyCode', (value.client as Client).currencyCode);
        });

        return () => {
            subscription.unsubscribe;
        };
    }, [form.watch, form.setValue]);

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        await onSubmit(values);
    }), [wrapSubmit, onSubmit]);

    const {
        fields: lineItemFields,
        append: appendLineItem,
        remove: removeLineItem,
    } = useFieldArray({control: form.control, name: 'lineItems'});

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                {invoice ? 'Edit' : 'Create'} Invoice
            </DialogTitle>

            <DialogContent dividers>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12}>
                        <RhfClientSelect
                            control={form.control}
                            name="client"
                            textFieldProps={{
                                label: 'Client',
                                required: true,
                                fullWidth: true,
                            }}
                            previousClient={invoice?.client}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfDatePicker
                            control={form.control}
                            name="issueDate"
                            label="Issue Date"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="dueAfterDays"
                            label="Due After"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="14">14 Days</MenuItem>
                            <MenuItem value="30">30 Days</MenuItem>
                        </RhfTextField>
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="discount"
                            label="Discount"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="vat"
                            label="VAT"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={12}>
                        <RhfTextField
                            control={form.control}
                            name="currencyCode"
                            label="Currency"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="EUR">Euro</MenuItem>
                            <MenuItem value="USD">US-Dollar</MenuItem>
                        </RhfTextField>
                    </Grid2>

                    {lineItemFields.map((lineItem, index) => (
                        <Grid2 xs={12} key={lineItem.id}>
                            <Card>
                                <CardContent>
                                    <Grid2 container spacing={2}>
                                        <Grid2 xs={12}>
                                            <RhfTextField
                                                control={form.control}
                                                name={`lineItems.${index}.description`}
                                                label="Description"
                                                fullWidth
                                                required
                                            />
                                        </Grid2>
                                        <Grid2 xs={12} sm={4}>
                                            <RhfTextField
                                                control={form.control}
                                                name={`lineItems.${index}.quantity`}
                                                label="Quantity"
                                                fullWidth
                                                required
                                            />
                                        </Grid2>
                                        <Grid2 xs={12} sm={4}>
                                            <RhfTextField
                                                control={form.control}
                                                name={`lineItems.${index}.unit`}
                                                label="Unit"
                                                fullWidth
                                                required
                                                select
                                            >
                                                <MenuItem value="none">None</MenuItem>
                                                <MenuItem value="hours">Hours</MenuItem>
                                                <MenuItem value="days">Days</MenuItem>
                                            </RhfTextField>
                                        </Grid2>
                                        <Grid2 xs={12} sm={4}>
                                            <RhfTextField
                                                control={form.control}
                                                name={`lineItems.${index}.unitAmount`}
                                                label="Amount"
                                                fullWidth
                                                required
                                            />
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => {
                                        removeLineItem(index);
                                    }}>
                                        Remove
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid2>
                    ))}
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    appendLineItem({
                        description: '',
                        quantity: '1',
                        unit: 'none',
                        unitAmount: '',
                    });
                }}>Add Line Item</Button>
                <Box sx={{flexGrow: 1}}/>

                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </DialogForm>
    );
};

const InvoiceDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={InvoiceForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default InvoiceDialog;
