import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './ClientDialog';
import ClientDialog from './ClientDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdateClientMutation} from '@/mutations/client';
import type {Client} from '@/types/client';

type Props = {
    open : boolean;
    onClose : () => void;
    client : Client;
};

const EditClientDialog = ({open, onClose, client} : Props) : JSX.Element => {
    const updateClientMutation = useUpdateClientMutation(client.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(updateClientMutation, values)).success) {
            enqueueSnackbar('Client has been updated', {variant: 'success'});
            onClose();
        }

        return false;
    }, [updateClientMutation, handleMutation, enqueueSnackbar]);

    return (
        <ClientDialog onSubmit={handleSubmit} open={open} onClose={onClose} client={client}/>
    );
};

export default EditClientDialog;
