import {Alert, LinearProgress, List} from '@mui/material';
import PaymentListItem from './PaymentListItem';
import RetryableError from '@/components/RetryableError';
import {usePaymentsQuery} from '@/queries/payment';
import type {Invoice} from '@/types/invoice';

type Props = {
    invoice : Invoice;
};

const PaymentsList = ({invoice} : Props) : JSX.Element => {
    const paymentsQuery = usePaymentsQuery(invoice.id);

    if (paymentsQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (paymentsQuery.isError) {
        return (
            <RetryableError
                message="Failed to load payments"
                onRetry={() => void paymentsQuery.refetch()}
            />
        );
    }

    const payments = paymentsQuery.data;

    if (payments.length === 0) {
        return (
            <Alert severity="info">There are no payments yet</Alert>
        );
    }

    return (
        <List disablePadding>
            {payments.map(payment => (
                <PaymentListItem key={payment.id} invoice={invoice} payment={payment}/>
            ))}
        </List>
    );
};

export default PaymentsList;
