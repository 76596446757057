import AutorenewIcon from '@mui/icons-material/Autorenew';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdateInvoiceStatusMutation} from '@/mutations/invoice';
import type {Invoice} from '@/types/invoice';

type Props = {
    invoice : Invoice;
};

const ToggleInvoiceStatusButton = ({invoice} : Props) : JSX.Element => {
    const updateInvoiceStatusMutation = useUpdateInvoiceStatusMutation(invoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleClick = async () => {
        if ((await handleMutation(updateInvoiceStatusMutation, {
            status: invoice.status === 'draft' ? 'sent' : 'draft',
        })).success) {
            enqueueSnackbar('Status has been updated', {variant: 'success'});
            return;
        }
    };

    return (
        <LoadingButton
            startIcon={<AutorenewIcon/>}
            variant="contained"
            loading={updateInvoiceStatusMutation.isLoading}
            onClick={handleClick}
        >
            Mark as {invoice.status === 'draft' ? 'sent' : 'draft'}
        </LoadingButton>
    );
};

export default ToggleInvoiceStatusButton;
