import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './ContactDialog';
import ContactDialog from './ContactDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateContactMutation} from '@/mutations/contact';
import type {Client} from '@/types/client';

type Props = {
    open : boolean;
    onClose : () => void;
    client : Client;
};

const CreateContactDialog = ({open, onClose, client} : Props) : JSX.Element => {
    const createContactMutation = useCreateContactMutation(client.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(createContactMutation, values)).success) {
            enqueueSnackbar('Contact has been added', {variant: 'success'});
            onClose();
        }

        return false;
    }, [createContactMutation, handleMutation, enqueueSnackbar]);

    return (
        <ContactDialog onSubmit={handleSubmit} open={open} onClose={onClose}/>
    );
};

export default CreateContactDialog;
