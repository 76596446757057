import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './RecurringInvoiceDialog';
import RecurringInvoiceDialog from './RecurringInvoiceDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdateRecurringInvoiceMutation} from '@/mutations/recurring-invoice';
import type {RecurringInvoice} from '@/types/recurring-invoice';

type Props = {
    open : boolean;
    onClose : () => void;
    recurringInvoice : RecurringInvoice;
};

const EditRecurringInvoiceDialog = ({open, onClose, recurringInvoice} : Props) : JSX.Element => {
    const updateRecurringInvoiceMutation = useUpdateRecurringInvoiceMutation(recurringInvoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(updateRecurringInvoiceMutation, {
            ...values,
            clientId: values.client.id,
            startDate: LocalDate.from(nativeJs(values.startDate)).toString(),
            endDate: LocalDate.from(nativeJs(values.endDate)).toString(),
            dueAfterDays: parseInt(values.dueAfterDays, 10),
            amount: parseInt(values.amount, 10),
        })).success) {
            enqueueSnackbar('Recurring invoice has been updated', {variant: 'success'});
            onClose();
        }

        return false;
    }, [updateRecurringInvoiceMutation, handleMutation, enqueueSnackbar]);

    return (
        <RecurringInvoiceDialog
            onSubmit={handleSubmit}
            open={open}
            onClose={onClose}
            recurringInvoice={recurringInvoice}
        />
    );
};

export default EditRecurringInvoiceDialog;
