import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useConfirm} from 'react-confirm-hook';
import {EditContactDialog} from './ContactDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useDeleteContactMutation} from '@/mutations/contact';
import type {Client} from '@/types/client';
import type {Contact} from '@/types/contact';

type Props = {
    client : Client;
    contact : Contact;
};

const ContactListItem = ({client, contact} : Props) : JSX.Element => {
    const deleteContactMutation = useDeleteContactMutation(client.id, contact.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const confirmDelete = useConfirm(ConfirmDialog);

    const handleDelete = async () => {
        if ((await handleMutation(deleteContactMutation, undefined)).success) {
            enqueueSnackbar('Contact has been deleted', {variant: 'success'});
        }
    };

    return (
        <ListItem
            disablePadding
            secondaryAction={(
                <IconButton edge="end" onClick={() => {
                    confirmDelete({
                        title: 'Confirm Delete',
                        message: 'Do you really want to delete this contact?',
                        onConfirm: handleDelete,
                    });
                }}>
                    <DeleteIcon/>
                </IconButton>
            )}
        >
            <ListItemButton onClick={() => {
                setEditDialogOpen(true);
            }}>
                <ListItemText
                    primary={`${contact.firstName} ${contact.lastName}`}
                    secondary={contact.emailAddress}
                />
            </ListItemButton>

            <EditContactDialog
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                client={client}
                contact={contact}
            />
        </ListItem>
    );
};

export default ContactListItem;
