import type {UseInfiniteQueryResult, UseQueryResult} from '@tanstack/react-query';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToInvoice, mapRawToLineItem} from '@/mappers/invoice';
import type {Invoice, LineItem, RawInvoice, RawLineItem} from '@/types/invoice';
import type {InfiniteCollectionResponse, CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useInvoicesQuery = (open : boolean) : UseInfiniteQueryResult<
    InfiniteCollectionResponse<Invoice>
> => {
    const fetch = useOidcFetch();

    return useInfiniteQuery(['invoices', open ? 'open' : 'closed'], async ({pageParam, signal}) => {
        const url = apiUrl('/invoices');
        url.searchParams.set('open', open ? 'true' : 'false');

        if (pageParam) {
            url.searchParams.set('cursor', pageParam as string);
        }

        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch clients');
        }

        const result = await response.json() as InfiniteCollectionResponse<RawInvoice>;

        return {
            items: result.items.map(mapRawToInvoice),
            nextCursor: result.nextCursor,
        };
    }, {
        getNextPageParam: lastPage => lastPage.nextCursor,
    });
};

export const useInvoiceQuery = (invoiceId : string) : UseQueryResult<Invoice> => {
    const fetch = useOidcFetch();

    return useQuery(['invoices', invoiceId], async ({signal}) => {
        const url = apiUrl(`/invoices/${invoiceId}`);
        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch invoice');
        }

        const rawInvoice = (await response.json()) as RawInvoice;
        return mapRawToInvoice(rawInvoice);
    });
};

export const useLineItemsQuery = (invoiceId : string) : UseQueryResult<LineItem[]> => {
    const fetch = useOidcFetch();

    return useQuery(['invoices', invoiceId, 'line-items'], async ({signal}) => {
        const url = apiUrl(`/invoices/${invoiceId}/line-items`);
        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch line items');
        }

        const rawLineItems = (await response.json() as CollectionResponse<RawLineItem>).items;
        return rawLineItems.map(mapRawToLineItem);
    });
};
