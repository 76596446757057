import type {UseInfiniteQueryResult} from '@tanstack/react-query';
import {useInfiniteQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToRecurringInvoice} from '@/mappers/recurring-invoice';
import type {RawRecurringInvoice, RecurringInvoice} from '@/types/recurring-invoice';
import type {InfiniteCollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useRecurringInvoicesQuery = (active : boolean) : UseInfiniteQueryResult<
    InfiniteCollectionResponse<RecurringInvoice>
> => {
    const fetch = useOidcFetch();

    return useInfiniteQuery(['recurring-invoices', active ? 'active' : 'inactive'], async ({pageParam, signal}) => {
        const url = apiUrl('/recurring-invoices');
        url.searchParams.set('active', active ? 'true' : 'false');

        if (pageParam) {
            url.searchParams.set('cursor', pageParam as string);
        }

        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch clients');
        }

        const result = await response.json() as InfiniteCollectionResponse<RawRecurringInvoice>;

        return {
            items: result.items.map(mapRawToRecurringInvoice),
            nextCursor: result.nextCursor,
        };
    }, {
        getNextPageParam: lastPage => lastPage.nextCursor,
    });
};
