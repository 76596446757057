import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToContact} from '@/mappers/contact';
import type {Contact, RawContact} from '@/types/contact';
import type {CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useContactsQuery = (clientId : string) : UseQueryResult<Contact[]> => {
    const fetch = useOidcFetch();

    return useQuery(['contacts', clientId], async ({signal}) => {
        const url = apiUrl(`/clients/${clientId}/contacts`);
        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch contacts');
        }

        const rawContacts = (await response.json() as CollectionResponse<RawContact>).items;
        return rawContacts.map(mapRawToContact);
    });
};
