import {Alert, LinearProgress, List} from '@mui/material';
import type {CSSProperties} from 'react';
import {forwardRef} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import VisualWindow from 'react-visual-window';
import RecurringInvoiceListItem from './RecurringInvoiceListItem';
import RetryableError from '@/components/RetryableError';
import {useRecurringInvoicesQuery} from '@/queries/recurring-invoice';
import type {RecurringInvoice} from '@/types/recurring-invoice';

type Props = {
    active : boolean;
};

type VirtualListItemProps = {
    style : CSSProperties;
    data : RecurringInvoice[];
    index : number;
};

const VirtualListItem = forwardRef<HTMLDivElement, VirtualListItemProps>((props, ref) => {
    return (
        <div ref={ref} style={props.style}>
            <RecurringInvoiceListItem
                recurringInvoice={props.data[props.index]}
            />
        </div>
    );
});
VirtualListItem.displayName = 'VirtualListItem';

const RecurringInvoiceList = ({active} : Props) : JSX.Element => {
    const recurringInvoicesQuery = useRecurringInvoicesQuery(active);

    if (recurringInvoicesQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (recurringInvoicesQuery.isError) {
        return (
            <RetryableError
                message="Failed to load recurring invoices"
                onRetry={() => void recurringInvoicesQuery.refetch()}
            />
        );
    }

    const {pages} = recurringInvoicesQuery.data;

    if (pages.length === 0 || pages[0].items.length === 0) {
        return (
            <Alert severity="info">There are no recurring invoices in this list</Alert>
        );
    }

    const items = pages.flatMap(page => page.items);

    return (
        <InfiniteScroll
            loadMore={() => {
                void recurringInvoicesQuery.fetchNextPage();
            }}
            hasMore={recurringInvoicesQuery.hasNextPage}
        >
            <List disablePadding>
                <VisualWindow defaultItemHeight={72} itemData={items}>
                    {VirtualListItem}
                </VisualWindow>
            </List>
        </InfiniteScroll>
    );
};

export default RecurringInvoiceList;
