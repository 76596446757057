import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {Invoice} from '@/types/invoice';
import {apiUrl} from '@/utils/api';

type Props = {
    invoice : Invoice;
};

type DownloadResponse = {
    url : string;
};

const DownloadInvoiceButton = ({invoice} : Props) : JSX.Element => {
    const [downloading, setDownloading] = useState(false);
    const fetch = useOidcFetch();
    const {enqueueSnackbar} = useSnackbar();

    const handleDownload = async () => {
        setDownloading(true);

        let response;

        try {
            response = await fetch(apiUrl(`/invoices/${invoice.id}/download-url`).toString());
        } catch {
            enqueueSnackbar('Failed to download invoice');
            return;
        } finally {
            setDownloading(false);
        }

        if (!response.ok) {
            enqueueSnackbar('Failed to download invoice');
        }

        const result = await response.json() as DownloadResponse;
        window.open(result.url);
    };

    return (
        <LoadingButton
            startIcon={<FileDownloadIcon/>}
            variant="contained"
            loading={downloading}
            onClick={handleDownload}
        >
            Download
        </LoadingButton>
    );
};

export default DownloadInvoiceButton;
