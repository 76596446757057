import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './PaymentDialog';
import PaymentDialog from './PaymentDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdatePaymentMutation} from '@/mutations/payment';
import type {Invoice} from '@/types/invoice';
import type {Payment} from '@/types/payment';

type Props = {
    open : boolean;
    onClose : () => void;
    invoice : Invoice;
    payment : Payment;
};

const EditPaymentDialog = ({open, onClose, invoice, payment} : Props) : JSX.Element => {
    const updateClientMutation = useUpdatePaymentMutation(invoice.id, payment.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(updateClientMutation, {
            receiptDate: LocalDate.from(nativeJs(values.receiptDate)).toString(),
            amount: parseInt(values.amount, 10),
        })).success) {
            enqueueSnackbar('Contact has been updated', {variant: 'success'});
            onClose();
        }

        return false;
    }, [updateClientMutation, handleMutation, enqueueSnackbar]);

    return (
        <PaymentDialog onSubmit={handleSubmit} open={open} onClose={onClose} payment={payment}/>
    );
};

export default EditPaymentDialog;
