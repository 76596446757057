import {Alert, LinearProgress, List} from '@mui/material';
import ContactListItem from './ContactListItem';
import RetryableError from '@/components/RetryableError';
import {useContactsQuery} from '@/queries/contact';
import type {Client} from '@/types/client';

type Props = {
    client : Client;
};

const ContactList = ({client} : Props) : JSX.Element => {
    const contactsQuery = useContactsQuery(client.id);

    if (contactsQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (contactsQuery.isError) {
        return (
            <RetryableError
                message="Failed to load contacts"
                onRetry={() => void contactsQuery.refetch()}
            />
        );
    }

    const contacts = contactsQuery.data;

    if (contacts.length === 0) {
        return (
            <Alert severity="info">There are no contacts yet</Alert>
        );
    }

    return (
        <List disablePadding>
            {contacts.map(contact => (
                <ContactListItem key={contact.id} client={client} contact={contact}/>
            ))}
        </List>
    );
};

export default ContactList;
