import {Paper, Table, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import LineItemsBody from './LineItemsBody';
import type {Invoice} from '@/types/invoice';

type Props = {
    invoice : Invoice;
};

const LineItemsTable = ({invoice} : Props) : JSX.Element => {
    const amountFormatter = new Intl.NumberFormat(
        'en-US',
        {style: 'currency', currency: invoice.currencyCode}
    );

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell align="right">Unit Amount</TableCell>
                    </TableRow>
                </TableHead>
                <LineItemsBody invoice={invoice} amountsFormatter={amountFormatter}/>
                <TableFooter>
                    {invoice.discount && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                Discount ({invoice.discount.toString()}%)
                            </TableCell>
                            <TableCell align="right">
                                {amountFormatter.format(invoice.amounts.discount / 100)}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={2}>
                            Subtotal
                        </TableCell>
                        <TableCell align="right">
                            {amountFormatter.format(invoice.amounts.discounted / 100)}
                        </TableCell>
                    </TableRow>
                    {invoice.vat && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                VAT ({invoice.vat.toString()}%)
                            </TableCell>
                            <TableCell align="right">
                                {amountFormatter.format(invoice.amounts.vat / 100)}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={2}>
                            Total amount
                        </TableCell>
                        <TableCell align="right">
                            {amountFormatter.format(invoice.amounts.total / 100)}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default LineItemsTable;
