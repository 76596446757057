import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {RawInvoice, RawLineItem} from '@/types/invoice';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type InvoiceValues = Omit<RawInvoice, 'id' | 'invoiceNumber' | 'client' | 'status' | 'amounts'> & {
    clientId : string;
    lineItems : RawLineItem[];
};

export const useCreateInvoiceMutation = () : UseMutationResult<
    void,
    unknown,
    InvoiceValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : InvoiceValues) => {
        const response = await fetch(apiUrl('/invoices').toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};

export const useUpdateInvoiceMutation = (invoiceId : string) : UseMutationResult<
    void,
    unknown,
    InvoiceValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : InvoiceValues) => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};

type InvoiceStatusValues = {
    status : 'draft' | 'sent';
};

export const useUpdateInvoiceStatusMutation = (invoiceId : string) : UseMutationResult<
    void,
    unknown,
    InvoiceStatusValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : InvoiceStatusValues) => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}/status`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update invoice status');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};

type SendValues = {
    contactIds : string[];
};

export const useSendInvoiceMutation = (invoiceId : string) : UseMutationResult<
    void,
    unknown,
    SendValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : SendValues) => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}/send`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to send invoice');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};
