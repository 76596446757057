import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToPayment} from '@/mappers/payment';
import type {Payment, RawPayment} from '@/types/payment';
import type {CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const usePaymentsQuery = (invoiceId : string) : UseQueryResult<Payment[]> => {
    const fetch = useOidcFetch();

    return useQuery(['invoices', invoiceId, 'payments'], async ({signal}) => {
        const url = apiUrl(`/invoices/${invoiceId}/payments`);
        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error('Unable to fetch payments');
        }

        const rawPayments = (await response.json() as CollectionResponse<RawPayment>).items;
        return rawPayments.map(mapRawToPayment);
    });
};
