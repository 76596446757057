import {zodResolver} from '@hookform/resolvers/zod';
import {convert} from '@js-joda/core';
import {Button, DialogActions, DialogContent, DialogTitle, MenuItem, useMediaQuery, useTheme} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DecimalJs from 'decimal.js';
import {RhfDatePicker, RhfTextField} from 'mui-rhf-integration';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import FormDialog from '@/components/FormDialog';
import type {FormDialogFormProps} from '@/components/FormDialog';
import RhfClientSelect from '@/components/RhfClientSelect/RhfClientSelect';
import type {Client} from '@/types/client';
import type {RecurringInvoice} from '@/types/recurring-invoice';
import {errorMap, zDecimalJs} from '@/utils/zod';

const schema = z.object({
    client: z.object({
        id: z.string(),
        taxable: z.boolean(),
        currencyCode: z.string(),
    }),
    startDate: z.date(),
    endDate: z.date(),
    period: z.enum(['monthly']).default('monthly'),
    dueAfterDays: z.string(),
    discount: z.preprocess(
        value => (!value || value === '') ? '0' : value,
        zDecimalJs
            .refine(value => value.greaterThanOrEqualTo(0), 'Value too small')
            .refine(value => value.lessThanOrEqualTo(100), 'Value too large')
            .transform(value => value.eq(0) ? null : value.toString())
    ),
    vat: z.preprocess(
        value => (!value || value === '') ? '0' : value,
        zDecimalJs
            .refine(value => value.greaterThanOrEqualTo(0), 'Value too small')
            .refine(value => value.lessThanOrEqualTo(100), 'Value too large')
            .transform(value => value.eq(0) ? null : value.toString())
    ),
    currencyCode: z.string().length(3).transform(value => value.toUpperCase()),
    amount: zDecimalJs
        .refine(value => value.greaterThan(0), 'Must be a positive value')
        .refine(value => value.decimalPlaces() <= 2, 'Must have two decimal places at most')
        .transform(value => value.mul(100).floor().toString()),
});

export type FormValues = z.infer<typeof schema>;

type Props = {
    recurringInvoice ?: RecurringInvoice;
    onSubmit : (values : FormValues) => Promise<boolean>;
    open : boolean;
    onClose : () => void;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const RecurringInvoiceForm = ({wrapSubmit, onClose, recurringInvoice, onSubmit} : FormProps) : JSX.Element => {
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: recurringInvoice
            ? {
                client: recurringInvoice.client,
                startDate: convert(recurringInvoice.startDate).toDate(),
                endDate: convert(recurringInvoice.endDate).toDate(),
                period: recurringInvoice.period,
                dueAfterDays: recurringInvoice.dueAfterDays.toString(),
                discount: recurringInvoice.discount?.toString(),
                vat: recurringInvoice.vat?.toString(),
                currencyCode: recurringInvoice.currencyCode,
                amount: new DecimalJs(recurringInvoice.amount).div(100).toFixed(2),
            }
            : {
                period: 'monthly',
                dueAfterDays: '30',
            },
    });

    useEffect(() => {
        const subscription = form.watch((value, {name, type}) => {
            if (name !== 'client' || type !== 'change') {
                return;
            }

            if (!value.client) {
                return;
            }

            form.setValue('vat', (value.client as Client).taxable ? '19' : '');
            form.setValue('currencyCode', (value.client as Client).currencyCode);
        });

        return () => {
            subscription.unsubscribe;
        };
    }, [form.watch, form.setValue]);

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        await onSubmit(values);
    }), [onSubmit]);

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                {recurringInvoice ? 'Edit' : 'Create'} Recurring Invoice
            </DialogTitle>

            <DialogContent dividers>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12}>
                        <RhfClientSelect
                            control={form.control}
                            name="client"
                            textFieldProps={{
                                label: 'Client',
                                required: true,
                                fullWidth: true,
                            }}
                            previousClient={recurringInvoice?.client}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfDatePicker
                            control={form.control}
                            name="startDate"
                            label="Start Date"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfDatePicker
                            control={form.control}
                            name="endDate"
                            label="End Date"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="period"
                            label="Period"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="monthly">Monthly</MenuItem>
                        </RhfTextField>
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="dueAfterDays"
                            label="Due After"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="14">14 Days</MenuItem>
                            <MenuItem value="30">30 Days</MenuItem>
                        </RhfTextField>
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="discount"
                            label="Discount"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="vat"
                            label="VAT"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="amount"
                            label="Amount"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="currencyCode"
                            label="Currency"
                            fullWidth
                            required
                            select
                        >
                            <MenuItem value="EUR">Euro</MenuItem>
                            <MenuItem value="USD">US-Dollar</MenuItem>
                        </RhfTextField>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </DialogForm>
    );
};

const RecurringInvoiceDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={RecurringInvoiceForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default RecurringInvoiceDialog;
