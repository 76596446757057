import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type {
    IconButtonProps} from '@mui/material';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader, Collapse, Divider,
    IconButton,
    styled,
    Typography,
} from '@mui/material';
import {countries} from 'countries-list';
import {formatAddress} from 'localized-address-format';
import {useState} from 'react';
import {EditClientDialog} from './ClientDialog';
import {CreateContactDialog} from './ContactDialog';
import ContactList from './ContactList';
import ToggleArchivedButton from './ToggleArchivedButton';
import type {Client} from '@/types/client';

type ExpandMoreProps = IconButtonProps & {
    expand : boolean;
};

const ExpandMore = styled((props : ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other}/>;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

type Props = {
    client : Client;
};

const ClientCard = ({client} : Props) : JSX.Element => {
    const [expanded, setExpanded] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [createContactDialogOpen, setCreateContactEditDialogOpen] = useState(false);

    const address = formatAddress({
        postalCode: client.zipCode,
        locality: client.city,
        postalCountry: client.countryCode,
        administrativeArea: client.state,
        addressLines: [client.addressLineOne, client.addressLineTwo].filter(Boolean),
    });
    address.push(countries[client.countryCode as keyof typeof countries].name);

    return (
        <Card>
            <CardActionArea onClick={() => {
                setEditDialogOpen(true);
            }}>
                <CardHeader
                    title={client.displayName !== '' ? client.displayName : client.name}
                />
                <CardContent>
                    <Typography sx={{whiteSpace: 'pre-line'}}>{address.join('\n')}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" onClick={() => {
                    setCreateContactEditDialogOpen(true);
                }}>
                    Add Contact
                </Button>
                <ToggleArchivedButton client={client}/>
                <Box sx={{flexGrow: 1}}/>
                <ExpandMore
                    expand={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <ExpandMoreIcon/>
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider/>
                <ContactList client={client}/>
            </Collapse>

            <EditClientDialog
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                client={client}
            />

            <CreateContactDialog
                open={createContactDialogOpen}
                onClose={() => {
                    setCreateContactEditDialogOpen(false);
                }}
                client={client}
            />
        </Card>
    );
};

export default ClientCard;
