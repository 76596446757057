import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import RecurringInvoiceDialog from './RecurringInvoiceDialog';
import type {FormValues} from './RecurringInvoiceDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateRecurringInvoiceMutation} from '@/mutations/recurring-invoice';

type Props = {
    open : boolean;
    onClose : () => void;
};

const CreateRecurringInvoiceDialog = ({open, onClose} : Props) : JSX.Element => {
    const createRecurringInvoiceMutation = useCreateRecurringInvoiceMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(createRecurringInvoiceMutation, {
            ...values,
            clientId: values.client.id,
            startDate: LocalDate.from(nativeJs(values.startDate)).toString(),
            endDate: LocalDate.from(nativeJs(values.endDate)).toString(),
            dueAfterDays: parseInt(values.dueAfterDays, 10),
            amount: parseInt(values.amount, 10),
        })).success) {
            enqueueSnackbar('Recurring invoice has been created', {variant: 'success'});
            onClose();
        }

        return false;
    }, [createRecurringInvoiceMutation, handleMutation, enqueueSnackbar]);

    return (
        <RecurringInvoiceDialog onSubmit={handleSubmit} open={open} onClose={onClose}/>
    );
};

export default CreateRecurringInvoiceDialog;
