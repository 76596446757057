import {useAuth0} from '@auth0/auth0-react';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar, Avatar,
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import {useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

const drawerWidth = 240;

const Layout = () : JSX.Element => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const {logout, user} = useAuth0();

    const navigate = useNavigate();

    if (!user) {
        throw new Error('Layout can only be used with signed in user');
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Stack sx={{p: 2}} direction="row" alignItems="flex-start">
                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    {user.picture && (
                        <Avatar src={user.picture} alt={user.email}/>
                    )}
                    <Typography noWrap sx={{mt: 1}}>
                        {user.email}
                    </Typography>
                </Box>
            </Stack>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        setMobileOpen(false);
                        navigate('/');
                    }}>
                        <ListItemIcon>
                            <DescriptionIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Invoices"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        setMobileOpen(false);
                        navigate('/recurring-invoices');
                    }}>
                        <ListItemIcon>
                            <EventRepeatIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Recurring Invoices"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        setMobileOpen(false);
                        navigate('/clients');
                    }}>
                        <ListItemIcon>
                            <BusinessIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Clients"/>
                    </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        setMobileOpen(false);
                        logout({returnTo: window.location.origin});
                    }}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Log Out"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar
                position="fixed"
                enableColorOnDark
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    marginLeft: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{marginRight: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Ajasta
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {sx: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, paddingTop: 4, paddingBottom: 4, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>
                <Container maxWidth="lg">
                    <Outlet/>
                </Container>
            </Box>
        </Box>
    );
};

export default Layout;
