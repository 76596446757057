import {zodResolver} from '@hookform/resolvers/zod';
import {convert} from '@js-joda/core';
import {Button, DialogActions, DialogContent, DialogTitle, Stack, useMediaQuery, useTheme} from '@mui/material';
import DecimalJs from 'decimal.js';
import {RhfDatePicker, RhfTextField} from 'mui-rhf-integration';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import FormDialog from '@/components/FormDialog';
import type {FormDialogFormProps} from '@/components/FormDialog';
import type {Payment} from '@/types/payment';
import {errorMap, zDecimalJs} from '@/utils/zod';

const schema = z.object({
    receiptDate: z.date(),
    amount: zDecimalJs
        .refine(value => value.greaterThan(0), 'Must be a positive value')
        .refine(value => value.decimalPlaces() <= 2, 'Must have two decimal places at most')
        .transform(value => value.mul(100).floor().toString()),
});

export type FormValues = z.infer<typeof schema>;

type Props = {
    payment ?: Payment;
    openAmount ?: number;
    onSubmit : (values : FormValues) => Promise<boolean>;
    open : boolean;
    onClose : () => void;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const PaymentForm = ({wrapSubmit, onClose, openAmount, payment, onSubmit} : FormProps) : JSX.Element => {
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: payment
            ? {
                receiptDate: convert(payment.receiptDate).toDate(),
                amount: new DecimalJs(payment.amount).div(100).toFixed(2),
            }
            : {
                receiptDate: new Date(),
                amount: openAmount ? new DecimalJs(openAmount).div(100).toFixed(2) : '0',
            },
    });

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        await onSubmit(values);
    }), [wrapSubmit, onSubmit]);

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                {payment ? 'Edit' : 'Create'} Payment
            </DialogTitle>

            <DialogContent dividers>
                <Stack spacing={2}>
                    <RhfDatePicker
                        control={form.control}
                        name="receiptDate"
                        label="Receipt Date"
                        textFieldProps={{
                            required: true,
                            fullWidth: true,
                        }}
                    />
                    <RhfTextField
                        control={form.control}
                        name="amount"
                        label="Amount"
                        fullWidth
                        required
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </DialogForm>
    );
};

const PaymentDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={PaymentForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'xs',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default PaymentDialog;
