import {zodResolver} from '@hookform/resolvers/zod';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {RhfSwitch, RhfTextField} from 'mui-rhf-integration';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import type {FormDialogFormProps} from '@/components/FormDialog';
import FormDialog from '@/components/FormDialog';
import type {Contact} from '@/types/contact';
import {errorMap} from '@/utils/zod';

const schema = z.object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    emailAddress: z.string().email(),
    receivesInvoices: z.boolean().default(false),
});

export type FormValues = z.infer<typeof schema>;

type Props = {
    contact ?: Contact;
    onSubmit : (values : FormValues) => Promise<boolean>;
    open : boolean;
    onClose : () => void;
};

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const ContactForm = ({wrapSubmit, onClose, contact, onSubmit} : FormProps) : JSX.Element => {
    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: contact,
    });

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        await onSubmit(values);
    }), [wrapSubmit, onSubmit]);

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                {contact ? 'Edit' : 'Create'} Contact
            </DialogTitle>

            <DialogContent dividers>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="firstName"
                            label="First Name"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <RhfTextField
                            control={form.control}
                            name="lastName"
                            label="Last Name"
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <RhfTextField
                            control={form.control}
                            name="emailAddress"
                            label="E-Mail Address"
                            type="email"
                            fullWidth
                            required
                        />
                    </Grid2>
                    <Grid2 xs={12} alignSelf="center">
                        <FormControlLabel
                            control={<RhfSwitch control={form.control} name="receivesInvoices"/>}
                            label="Receives Invoices"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </DialogForm>
    );
};

const ContactDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={ContactForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default ContactDialog;
