import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './InvoiceDialog';
import InvoiceDialog from './InvoiceDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateInvoiceMutation} from '@/mutations/invoice';
import type {RawLineItem} from '@/types/invoice';

type Props = {
    open : boolean;
    onClose : () => void;
};

const CreateInvoiceDialog = ({open, onClose} : Props) : JSX.Element => {
    const createInvoiceMutation = useCreateInvoiceMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(createInvoiceMutation, {
            ...values,
            clientId: values.client.id,
            issueDate: LocalDate.from(nativeJs(values.issueDate)).toString(),
            dueAfterDays: parseInt(values.dueAfterDays, 10),
            lineItems: values.lineItems.map((lineItem) : RawLineItem => ({
                description: lineItem.description,
                quantity: parseInt(lineItem.quantity, 10),
                unit: lineItem.unit,
                unitAmount: parseInt(lineItem.unitAmount),
            })),
        })).success) {
            enqueueSnackbar('Recurring invoice has been created', {variant: 'success'});
            onClose();
        }

        return false;
    }, [createInvoiceMutation, handleMutation, enqueueSnackbar]);

    return (
        <InvoiceDialog onSubmit={handleSubmit} open={open} onClose={onClose}/>
    );
};

export default CreateInvoiceDialog;
