import AddBoxIcon from '@mui/icons-material/AddBox';
import {Button, Paper, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import {CreateClientDialog} from './ClientDialog';
import ClientList from './ClientList';

const Clients = () : JSX.Element => {
    const [currentTab, setCurrentTab] = useState('active');
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{display: 'flex', pl: 2, pr: 2, pb: 1, mb: 2, borderBottom: 1, borderColor: 'divider'}}
            >
                <Typography variant="h6">
                    Clients
                </Typography>

                <Button
                    size="small"
                    startIcon={<AddBoxIcon/>}
                    onClick={() => {
                        setCreateDialogOpen(true);
                    }}
                >
                    Create client
                </Button>
            </Stack>

            <CreateClientDialog open={createDialogOpen} onClose={() => {
                setCreateDialogOpen(false);
            }}/>

            <Paper sx={{mb: 2}}>
                <Tabs value={currentTab} onChange={(event, value) => {
                    setCurrentTab(value as string);
                }}>
                    <Tab value="active" label="Active"/>
                    <Tab value="archived" label="Archived"/>
                </Tabs>
            </Paper>

            <ClientList archived={currentTab === 'archived'}/>
        </>
    );
};

export default Clients;
