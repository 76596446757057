import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './PaymentDialog';
import PaymentDialog from './PaymentDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreatePaymentMutation} from '@/mutations/payment';
import type {Invoice} from '@/types/invoice';

type Props = {
    open : boolean;
    onClose : () => void;
    invoice : Invoice;
};

const CreatePaymentDialog = ({open, onClose, invoice} : Props) : JSX.Element => {
    const createPaymentMutation = useCreatePaymentMutation(invoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(createPaymentMutation, {
            receiptDate: LocalDate.from(nativeJs(values.receiptDate)).toString(),
            amount: parseInt(values.amount, 10),
        })).success) {
            enqueueSnackbar('Payment has been added', {variant: 'success'});
            onClose();
        }

        return false;
    }, [createPaymentMutation, handleMutation, enqueueSnackbar]);

    return (
        <PaymentDialog onSubmit={handleSubmit} open={open} onClose={onClose} openAmount={invoice.amounts.open}/>
    );
};

export default CreatePaymentDialog;
