import {LinearProgress, TableBody, TableCell, TableRow} from '@mui/material';
import RetryableError from '@/components/RetryableError';
import {useLineItemsQuery} from '@/queries/invoice';
import type {Invoice} from '@/types/invoice';

type Props = {
    invoice : Invoice;
    amountsFormatter : Intl.NumberFormat;
};

const LineItemsBody = ({invoice, amountsFormatter} : Props) : JSX.Element => {
    const lineItemsQuery = useLineItemsQuery(invoice.id);

    if (lineItemsQuery.isLoading) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={3}>
                        <LinearProgress variant="indeterminate"/>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    if (lineItemsQuery.isError) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={3}>
                        <RetryableError
                            message="Failed to load clients"
                            onRetry={() => void lineItemsQuery.refetch()}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    const lineItems = lineItemsQuery.data;

    return (
        <TableBody>
            {lineItems.map((lineItem, index) => (
                <TableRow key={index}>
                    <TableCell>{lineItem.description}</TableCell>
                    <TableCell>
                        {lineItem.quantity}
                        {lineItem.unit === 'hours' ? ' hours' : lineItem.unit === 'days' ? ' days' : ''}
                    </TableCell>
                    <TableCell align="right">{amountsFormatter.format(lineItem.unitAmount / 100)}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

export default LineItemsBody;
