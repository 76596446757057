import {ListItem, ListItemButton, ListItemText} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import type {Invoice} from '@/types/invoice';

type Props = {
    invoice : Invoice;
};

const InvoiceListItem = ({invoice} : Props) : JSX.Element => {
    const navigate = useNavigate();
    const {client} = invoice;

    return (
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate(`/invoices/${invoice.id}`);
            }}>
                <ListItemText
                    primary={client.displayName !== '' ? client.displayName : client.name}
                    secondary={invoice.invoiceNumber}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default InvoiceListItem;
