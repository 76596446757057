import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useConfirm} from 'react-confirm-hook';
import {EditRecurringInvoiceDialog} from './RecurringInvoiceDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useDeleteRecurringInvoiceMutation} from '@/mutations/recurring-invoice';
import type {RecurringInvoice} from '@/types/recurring-invoice';

type Props = {
    recurringInvoice : RecurringInvoice;
};

const monthlyFormatter = DateTimeFormatter.ofPattern('MMMM yyyy').withLocale(Locale.US);

const RecurringInvoiceListItem = ({recurringInvoice} : Props) : JSX.Element => {
    const deleteRecurringInvoiceMutation = useDeleteRecurringInvoiceMutation(recurringInvoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const confirmDelete = useConfirm(ConfirmDialog);
    const {client} = recurringInvoice;

    const handleDelete = async () => {
        if ((await handleMutation(deleteRecurringInvoiceMutation, undefined)).success) {
            enqueueSnackbar('Recurring invoice has been deleted', {variant: 'success'});
        }
    };

    return (
        <ListItem
            disablePadding
            secondaryAction={(
                <IconButton edge="end" onClick={() => {
                    confirmDelete({
                        title: 'Confirm Delete',
                        message: 'Do you really want to delete this recurring invoice?',
                        onConfirm: handleDelete,
                    });
                }}>
                    <DeleteIcon/>
                </IconButton>
            )}
        >
            <ListItemButton onClick={() => {
                setEditDialogOpen(true);
            }}>
                <ListItemText
                    primary={client.displayName !== '' ? client.displayName : client.name}
                    secondary={[
                        recurringInvoice.startDate.format(monthlyFormatter),
                        recurringInvoice.endDate.format(monthlyFormatter),
                    ].join(' - ')}
                />
            </ListItemButton>

            <EditRecurringInvoiceDialog
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                recurringInvoice={recurringInvoice}
            />
        </ListItem>
    );
};

export default RecurringInvoiceListItem;
