import {LocalDate, nativeJs} from '@js-joda/core';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import type {FormValues} from './InvoiceDialog';
import InvoiceDialog from './InvoiceDialog';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdateInvoiceMutation} from '@/mutations/invoice';
import type {Invoice, LineItem, RawLineItem} from '@/types/invoice';

type Props = {
    open : boolean;
    onClose : () => void;
    invoice : Invoice;
    lineItems : LineItem[];
};

const EditInvoiceDialog = ({open, onClose, invoice, lineItems} : Props) : JSX.Element => {
    const updateInvoiceMutation = useUpdateInvoiceMutation(invoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = useCallback(async (values : FormValues) : Promise<boolean> => {
        if ((await handleMutation(updateInvoiceMutation, {
            ...values,
            clientId: values.client.id,
            issueDate: LocalDate.from(nativeJs(values.issueDate)).toString(),
            dueAfterDays: parseInt(values.dueAfterDays, 10),
            lineItems: values.lineItems.map((lineItem) : RawLineItem => ({
                description: lineItem.description,
                quantity: parseInt(lineItem.quantity, 10),
                unit: lineItem.unit,
                unitAmount: parseInt(lineItem.unitAmount),
            })),
        })).success) {
            enqueueSnackbar('Invoice has been updated', {variant: 'success'});
            onClose();
        }

        return false;
    }, [updateInvoiceMutation, handleMutation, enqueueSnackbar]);

    return (
        <InvoiceDialog
            onSubmit={handleSubmit}
            open={open}
            onClose={onClose}
            invoice={invoice}
            lineItems={lineItems}
        />
    );
};

export default EditInvoiceDialog;
