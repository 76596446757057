import type {DialogProps} from '@mui/material';
import {Dialog} from '@mui/material';
import type {JSXElementConstructor} from 'react';
import {useCallback, useState} from 'react';
import type {SubmitHandler} from 'react-hook-form';

export type FormDialogFormProps<TFieldValues> = {
    wrapSubmit : (submitHandler : SubmitHandler<TFieldValues>) => SubmitHandler<TFieldValues>;
    onClose : () => void;
};

type Props<TFormProps, TFieldValues> = {
    formComponent : JSXElementConstructor<TFormProps & FormDialogFormProps<TFieldValues>>;
    formProps : TFormProps;
    dialogProps : Omit<DialogProps, 'open' | 'onClose'>;
    open : boolean;
    onClose : () => void;
};

const FormDialog = <TFormProps, TFieldValues>(
    {formProps, dialogProps, open, onClose, ...rest} : Props<TFormProps, TFieldValues>
) : JSX.Element => {
    const [submitting, setSubmitting] = useState(false);

    const wrapSubmit = useCallback((
        submitHandler : SubmitHandler<TFieldValues>
    ) : SubmitHandler<TFieldValues> => {
        return async (values : TFieldValues) => {
            setSubmitting(true);

            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return await submitHandler(values);
            } finally {
                setSubmitting(false);
            }
        };
    }, []);

    const handleClose = () => {
        if (submitting) {
            return;
        }

        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            {...dialogProps}
        >
            <rest.formComponent {...formProps} wrapSubmit={wrapSubmit} onClose={handleClose}/>
        </Dialog>
    );
};

export default FormDialog;
