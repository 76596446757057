import {Alert, LinearProgress, List} from '@mui/material';
import type {CSSProperties} from 'react';
import {forwardRef} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import VisualWindow from 'react-visual-window';
import InvoiceListItem from './InvoiceListItem';
import RetryableError from '@/components/RetryableError';
import {useInvoicesQuery} from '@/queries/invoice';
import type {Invoice} from '@/types/invoice';

type Props = {
    open : boolean;
};

type VirtualListItemProps = {
    style : CSSProperties;
    data : Invoice[];
    index : number;
};

const VirtualListItem = forwardRef<HTMLDivElement, VirtualListItemProps>((props, ref) => {
    return (
        <div ref={ref} style={props.style}>
            <InvoiceListItem
                invoice={props.data[props.index]}
            />
        </div>
    );
});
VirtualListItem.displayName = 'VirtualListItem';

const InvoiceList = ({open} : Props) : JSX.Element => {
    const invoicesQuery = useInvoicesQuery(open);

    if (invoicesQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (invoicesQuery.isError) {
        return (
            <RetryableError
                message="Failed to load invoices"
                onRetry={() => void invoicesQuery.refetch()}
            />
        );
    }

    const {pages} = invoicesQuery.data;

    if (pages.length === 0 || pages[0].items.length === 0) {
        return (
            <Alert severity="info">There are no invoices in this list</Alert>
        );
    }

    const items = pages.flatMap(page => page.items);

    return (
        <InfiniteScroll
            loadMore={() => {
                void invoicesQuery.fetchNextPage();
            }}
            hasMore={invoicesQuery.hasNextPage}
        >
            <List disablePadding>
                <VisualWindow defaultItemHeight={72} itemData={items}>
                    {VirtualListItem}
                </VisualWindow>
            </List>
        </InfiniteScroll>
    );
};

export default InvoiceList;
