import type {Auth0ProviderOptions} from '@auth0/auth0-react';
import {Auth0Provider} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

type Props = Auth0ProviderOptions;

const Auth0ProviderWithNavigate = (props : Props) : JSX.Element => {
    const navigate = useNavigate();

    return (
        <Auth0Provider
            {...props}
            onRedirectCallback={appState => {
                if (appState?.returnTo) {
                    navigate(appState.returnTo);
                }
            }}
        />
    );
};

export default Auth0ProviderWithNavigate;
