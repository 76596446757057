import {zodResolver} from '@hookform/resolvers/zod';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {RhfCheckboxGroup} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import type {FormDialogFormProps} from '@/components/FormDialog';
import FormDialog from '@/components/FormDialog';
import RetryableError from '@/components/RetryableError';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useSendInvoiceMutation} from '@/mutations/invoice';
import {useContactsQuery} from '@/queries/contact';
import type {Invoice} from '@/types/invoice';
import {errorMap} from '@/utils/zod';

type Props = {
    invoice : Invoice;
    open : boolean;
    onClose : () => void;
};

const schema = z.object({
    contactIds: z.array(z.string().uuid()).min(1),
});

type FormValues = z.infer<typeof schema>;

type FormProps = FormDialogFormProps<FormValues> & Omit<Props, 'open' | 'onClose'>;

const SendInvoiceForm = ({wrapSubmit, onClose, invoice} : FormProps) : JSX.Element => {
    const contactsQuery = useContactsQuery(invoice.client.id);
    const sendInvoiceMutation = useSendInvoiceMutation(invoice.id);
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
    });

    const handleSubmit = useCallback(wrapSubmit(async (values : FormValues) : Promise<void> => {
        if ((await handleMutation(sendInvoiceMutation, values)).success) {
            enqueueSnackbar('Invoice has been sent', {variant: 'success'});
            onClose();
        }
    }), [wrapSubmit]);

    const radioGroup = useMemo(() => {
        if (contactsQuery.isLoading) {
            return <LinearProgress variant="indeterminate"/>;
        }

        if (contactsQuery.isError) {
            return (
                <RetryableError
                    message="Failed to load contacts"
                    onRetry={() => void contactsQuery.refetch()}
                />
            );
        }

        const options = contactsQuery.data.map(contact => ({
            value: contact.id,
            label: `${contact.firstName} ${contact.lastName}`,
        }));

        return (
            <RhfCheckboxGroup
                control={form.control}
                name="contactIds"
                label="Contacts"
                options={options}
            />
        );
    }, [contactsQuery, form.control]);

    useEffect(() => {
        if (!contactsQuery.data) {
            return;
        }

        const values = form.getValues() as {contactIds : string[] | undefined};

        if (values.contactIds) {
            return;
        }

        const preSelectedIds = contactsQuery.data
            .filter(contact => contact.receivesInvoices)
            .map(contact => contact.id);

        form.setValue('contactIds', preSelectedIds);
    }, [contactsQuery.data, form.getValues, form.setValue]);

    return (
        <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <DialogTitle>
                Send Invoice
            </DialogTitle>

            <DialogContent dividers>
                {radioGroup}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Send</Button>
            </DialogActions>
        </DialogForm>
    );
};

const SendInvoiceDialog = ({open, onClose, ...formProps} : Props) : JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormDialog
            formComponent={SendInvoiceForm}
            formProps={formProps}
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                fullScreen: fullScreen,
            }}
            open={open}
            onClose={onClose}
        />
    );
};

export default SendInvoiceDialog;
