import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {RawPayment} from '@/types/payment';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type PaymentValues = Omit<RawPayment, 'id'>;

export const useCreatePaymentMutation = (invoiceId : string) : UseMutationResult<
    void,
    unknown,
    PaymentValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : PaymentValues) => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}/payments`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create payment');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};

export const useUpdatePaymentMutation = (invoiceId : string, paymentId : string) : UseMutationResult<
    void,
    unknown,
    PaymentValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : PaymentValues) => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}/payments/${paymentId}`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update payment');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};

export const useDeletePaymentMutation = (invoiceId : string, paymentId : string) : UseMutationResult<
    void,
    unknown,
    void
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async () => {
        const response = await fetch(apiUrl(`/invoices/${invoiceId}/payments/${paymentId}`).toString(), {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to delete payment');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['invoices']);
        },
    });
};
