import {Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import Clients from '@/pages/Clients';
import Invoices, {Invoice} from '@/pages/Invoices';
import RecurringInvoices from '@/pages/RecurringInvoices';

const App = () : JSX.Element => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Invoices/>}/>
                <Route path="invoices/:invoiceId" element={<Invoice/>}/>
                <Route path="recurring-invoices" element={<RecurringInvoices/>}/>
                <Route path="clients" element={<Clients/>}/>
            </Route>
        </Routes>
    );
};

export default App;
