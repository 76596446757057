import {Alert, LinearProgress, Stack} from '@mui/material';
import ClientCard from './ClientCard';
import RetryableError from '@/components/RetryableError';
import {useClientsQuery} from '@/queries/client';

type Props = {
    archived : boolean;
};

const ClientList = ({archived} : Props) : JSX.Element => {
    const clientsQuery = useClientsQuery(archived);

    if (clientsQuery.isLoading) {
        return <LinearProgress variant="indeterminate"/>;
    }

    if (clientsQuery.isError) {
        return (
            <RetryableError
                message="Failed to load clients"
                onRetry={() => void clientsQuery.refetch()}
            />
        );
    }

    const clients = clientsQuery.data;

    if (clients.length === 0) {
        return (
            <Alert severity="info">There are no clients in this list</Alert>
        );
    }

    return (
        <Stack spacing={2}>
            {clients.map(client => (
                <ClientCard key={client.id} client={client}/>
            ))}
        </Stack>
    );
};

export default ClientList;
