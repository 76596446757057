import type {TextFieldProps} from '@mui/material';
import {RhfAutocomplete} from 'mui-rhf-integration';
import {useMemo} from 'react';
import type {Control, FieldPath, FieldValues} from 'react-hook-form';
import {useClientsQuery} from '@/queries/client';
import type {Client} from '@/types/client';

const collator = new Intl.Collator('en-US', {sensitivity: 'base'});

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    control : Control<TFieldValues>;
    name : TName;
    textFieldProps ?: Omit<TextFieldProps, 'error' | 'onChange' | 'onBlur' | 'value' | 'inputRef'>;
    previousClient ?: Client;
};

const RhfClientSelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({control, name, textFieldProps, previousClient} : Props<TFieldValues, TName>) : JSX.Element => {
    const clientsQuery = useClientsQuery(false);
    const selectableClients = useMemo(() => {
        if (!clientsQuery.data) {
            return previousClient ? [previousClient] : [];
        }

        if (previousClient && !clientsQuery.data.some(client => client.id === previousClient.id)) {
            return [...clientsQuery.data, previousClient];
        }

        return clientsQuery.data;
    }, [clientsQuery.data, previousClient]);
    const options = useMemo(() => {
        return selectableClients.map(client => {
            const label = client.displayName !== '' ? client.displayName : client.name;

            return {
                taxable: client.taxable,
                currencyCode: client.currencyCode,
                id: client.id,
                label,
                token: label.toLowerCase(),
            };
        });
    }, [selectableClients]);
    const sortedOptions = useMemo(() => {
        return options.sort((a, b) => collator.compare(a.label, b.label));
    }, [options]);

    return (
        <RhfAutocomplete
            control={control}
            name={name}
            options={sortedOptions}
            freeSolo={false}
            loading={clientsQuery.isLoading || clientsQuery.isError}
            getOptionLabel={option => option.label}
            valueToOption={value => {
                if (!value) {
                    return undefined;
                }

                return sortedOptions.find(option => option.id === (value as Client).id);
            }}
            filterOptions={(options, state) => {
                if (state.inputValue === '') {
                    return options;
                }

                const token = state.inputValue.toLowerCase();
                return options.filter(option => option.token.includes(token));
            }}
            textFieldProps={textFieldProps}
        />
    );
};

export default RhfClientSelect;
