import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {RawContact} from '@/types/contact';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type ContactValues = Omit<RawContact, 'id'>;

export const useCreateContactMutation = (clientId : string) : UseMutationResult<
    void,
    unknown,
    ContactValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : ContactValues) => {
        const response = await fetch(apiUrl(`/clients/${clientId}/contacts`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create contact');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['contacts', clientId]);
        },
    });
};

export const useUpdateContactMutation = (clientId : string, contactId : string) : UseMutationResult<
    void,
    unknown,
    ContactValues
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async (values : ContactValues) => {
        const response = await fetch(apiUrl(`/clients/${clientId}/contacts/${contactId}`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update contact');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['contacts', clientId]);
        },
    });
};

export const useDeleteContactMutation = (clientId : string, contactId : string) : UseMutationResult<
    void,
    unknown,
    void
> => {
    const fetch = useOidcFetch();
    const queryClient = useQueryClient();

    return useMutation(async () => {
        const response = await fetch(apiUrl(`/clients/${clientId}/contacts/${contactId}`).toString(), {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to delete contact');
        }
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['contacts', clientId]);
        },
    });
};
